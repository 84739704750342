<template>
  <div class="py-3 text-sm text-gray-500 breadcrumb-nav theme-text-base font-figtree bg-gray-100 shadow-inner"
    v-if="breadcrumbs.breadcrumbsList?.length">
    <div class="px-6 mx-auto xl:px-0 max-w-screen-xl">
      <ul class="flex flex-wrap">
        <li class="inline-flex items-center justify-center py-1">
          <NuxtLink to="/">
            <HomeIcon class="w-5 h-5" alt="Home" />
          </NuxtLink>
        </li>
        <li v-for="crumb in breadcrumbs.breadcrumbsList"
          class="before:content-['/'] inline-flex items-center justify-center py-1">
          <a v-if="crumb.refreshPage" :href="crumb.link" :key="crumb.link" class="whitespace-nowrap">
            {{ crumb.title }}
          </a>
          <NuxtLink v-else :href="crumb.link" :key="crumb.link" class="whitespace-nowrap">
            {{ crumb.title }}
          </NuxtLink>
        </li>
        <li class="ml-auto hidden lg:block">
          <NuxtLink to="/interactive-home-design/"
            class="whitespace-nowrap inline-flex items-center justify-center py-1 pr-2">
            <LightBulbIcon class="w-4 h-4 mx-2 group-hover:text-gray-500" aria-hidden="true" />
            Interactive Home Design
          </NuxtLink>
        </li>
        <li class="hidden lg:block">
          <NuxtLink to="tel:6364898384" class="before:content-['/'] inline-flex items-center justify-center py-1 pr-2">
            <PhoneIcon class="w-4 h-4 mx-2 group-hover:text-gray-500" aria-hidden="true" />
            636-489-8384
          </NuxtLink>
        </li>
        <li class="hidden lg:block">
          <NuxtLink to="sms:6364898384" class="before:content-['/'] inline-flex items-center justify-center py-1">
            <AnnotationIcon class="w-4 h-4 mx-2 group-hover:text-gray-500" aria-hidden="true" />
            636-489-8384
          </NuxtLink>
        </li>
      </ul>
    </div>
  </div>
</template>

<style scoped>
li {
  &::before {
    @apply mx-3;
  }
}
</style>

<script setup>
import { HomeIcon, PhoneIcon, AnnotationIcon, LightBulbIcon } from "@heroicons/vue/solid/index.js";
const breadcrumbs = useBreadcrumbs();
const route = useRoute();
watch(
  () => route.query,
  () => refreshNuxtData("route")
);
</script>
